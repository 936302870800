import React, { useEffect, useRef, useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const { userLevel } = useSelector(state => state?.AuthUser)

  const activateParentDropdown = useCallback(item => {
    // Lógica para activar el menú desplegable del padre
  }, [])

  const path = useLocation()

  const activeMenu = useCallback(() => {
    // Lógica para activar el menú basado en la ruta actual
  }, [path.pathname])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [activeMenu])

  // Configuración centralizada para los menús
  const menuConfig = [
    {
      level: [1],
      path: "/dashboard",
      icon: "bx bx-home-circle",
      label: "Dashboard",
    },
    {
      level: [1, 2, 3],
      path: "/job-list",
      icon: "bx bx-list-ul",
      label: "Lista de trabajos",
    },
    {
      level: [1],
      path: "/administration",
      icon: "bx bx-user",
      label: "Administración",
    },
    {
      level: [1, 2],
      path: "/reports",
      icon: "bx bx-upload",
      label: "Reportes",
    },
    {
      level: [1, 2, 3],
      path: "/logout",
      icon: "bx bx-power-off",
      label: "Logout",
    },
  ]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")}</li>
            {menuConfig
              .filter(menu => menu.level.includes(userLevel)) // Filtra según el nivel del usuario
              .map((menu, index) => (
                <li key={index}>
                  <Link to={menu.path} className="waves-effect">
                    <i className={menu.icon}></i>
                    <span>{props.t(menu.label)}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
